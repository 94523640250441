import React from 'react'
import { Center, Stack, Text, Box, PrimaryButton } from '../common'
import { Form, Input, Select } from 'antd'
import { Layout } from '../components/Layout'
import { EMAIL_REGEX, MOBILE_NO_REGEX } from '../common/utils'
import { useContactUs } from '../components/useContactUs'
import { ZHelmet } from '../components/ZHelmet'

export default function ApplyForLoan(props) {
  const [form] = Form.useForm()
  const { loading, sendData: handleFinish } = useContactUs(() => {
    form.resetFields()
  })

  return (
    <Layout>
      <ZHelmet
        title="Instant Personal Loan | Application Form | Apply Online | Zavron Finserv"
        description="Are you looking forward to apply an instant personal loan online do it with Zavron Finserv to get a quick disbursal that too with easy hassle free repayment tenures"
      />
      <Center gutters="var(--s2)" maxWidth="var(--max-width)" intrinsic>
        <Stack space="var(--s3)">
          <Stack space="var(--s-2)">
            <Text style={{ textAlign: 'center' }} variant="h2">
              Apply Loan
            </Text>
            <Text
              variant="h4"
              style={{
                fontFamily: 'var(--body-font-family)',
                textAlign: 'center',
              }}>
              {"Please fill the form below. We'll get in touch with you."}
            </Text>
          </Stack>
          <Box>
            <Form form={form} onFinish={handleFinish} layout="vertical">
              <Form.Item
                label="Select Loan Type"
                name="loanType"
                initialValue="Personal Loan"
                rules={[{ required: true, message: 'Please select loan type' }]}>
                <Select
                  style={{ borderRadius: 5 }}
                  size="large"
                  options={[
                    {
                      label: 'Personal Loan',
                      value: 'Personal Loan',
                    },
                    {
                      label: 'Business Loan',
                      value: 'Business Loan',
                    },
                    {
                      label: 'Used Car Loan',
                      value: 'Used Car Loan',
                    },
                    {
                      label: 'Two Wheeler Loan',
                      value: 'Two Wheeler Loan',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Full Name"
                name="fullName"
                rules={[
                  { required: true, message: 'Please enter a valid name' },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>
              <Form.Item
                label="Contact Number"
                name="contactNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid contact number',
                  },
                  {
                    validator: (_, value) => {
                      // if (value && MOBILE_NO_REGEX.test(value)) {
                      //   callback()
                      // } else {
                      //   callback('Please enter a valid contact number')
                      // }

                      if (!value || MOBILE_NO_REGEX.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please enter a valid contact number');

                    },
                  },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>


              <Form.Item
                label="Email-id"
                name="emailid"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid email-id',
                  },
                  {
                    validator: (_, value) => {
                      // if (value && EMAIL_REGEX.test(value)) {
                      //   callback()
                      // } else {
                      //   callback('Please enter a valid email-id')
                      // }

                      if (!value || EMAIL_REGEX.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please enter a valid email-id');

                    },
                  },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>

              <Form.Item label="Your Message" name="message">
                <Input.TextArea style={{ borderRadius: 5 }} />
              </Form.Item>
              <Form.Item>
                <PrimaryButton
                  loading={loading}
                  style={{ width: '100%' }}
                  htmlType="submit">
                  Apply Loan
                </PrimaryButton>
              </Form.Item>
            </Form>
          </Box>
        </Stack>
      </Center>
    </Layout>
  )
}
